<template>
    <div v-if="editor">
        <editor-menu class="editor__header" :editor="editor" />
        <editor-content v-bind="$attrs" :editor="editor" />
    </div>
</template>

<script>
import { editorMixin } from './editor.mixin';

export default {
    mixins: [editorMixin],
    inheritAttrs: false
};
</script>
