<template>
    <div>
        <template v-for="(item, index) in items">
            <div v-if="item.type === 'divider'" :key="`divider${index}`" class="divider" />
            <editor-item-group v-else-if="item.type === 'group'" :key="index" :items="item" />
            <editor-item v-else :key="index" v-bind="item" />
        </template>
    </div>
</template>

<script>
import EditorItem from './EditorItem.vue';
import EditorItemGroup from './EditorItemGroup.vue';

export default {
    components: {
        EditorItem,
        EditorItemGroup
    },

    props: {
        editor: {
            type: Object,
            required: true
        },
        customMenuItems: {
            type: Array,
            required: false
        }
    },

    data() {
        return {
            items: [
                {
                    icon: 'bold',
                    title: this.$t('editor.menuItems.bold'),
                    action: () => this.editor.chain().focus().toggleBold().run(),
                    isActive: () => this.editor.isActive('bold')
                },
                {
                    icon: 'italic',
                    title: this.$t('editor.menuItems.italic'),
                    action: () => this.editor.chain().focus().toggleItalic().run(),
                    isActive: () => this.editor.isActive('italic')
                },
                {
                    icon: 'paragraph',
                    title: this.$t('editor.menuItems.paragraph'),
                    action: () => this.editor.chain().focus().setParagraph().run(),
                    isActive: () => this.editor.isActive('paragraph')
                },
                {
                    type: 'divider'
                },
                {
                    icon: 'list-unordered',
                    title: this.$t('editor.menuItems.bullet_list'),
                    action: () => this.editor.chain().focus().toggleBulletList().run(),
                    isActive: () => this.editor.isActive('bulletList')
                },
                {
                    icon: 'list-ordered',
                    title: this.$t('editor.menuItems.ordered_list'),
                    action: () => this.editor.chain().focus().toggleOrderedList().run(),
                    isActive: () => this.editor.isActive('orderedList')
                },
                {
                    type: 'divider'
                },
                {
                    icon: 'arrow-go-back-line',
                    title: this.$t('editor.menuItems.undo'),
                    action: () => this.editor.chain().focus().undo().run()
                },
                {
                    icon: 'arrow-go-forward-line',
                    title: this.$t('editor.menuItems.redo'),
                    action: () => this.editor.chain().focus().redo().run()
                },
                ...[
                    this.customMenuItems
                        ? {
                              type: 'divider'
                          }
                        : null
                ],
                ...(this.customMenuItems ?? [])
            ].filter(Boolean)
        };
    }
};
</script>

<style lang="scss">
.divider {
    width: 2px;
    height: 1.25rem;
    background-color: rgba(#000, 0.1);
    margin-left: 0.5rem;
    margin-right: 0.75rem;
    display: inline;
}
</style>
