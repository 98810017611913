<template>
    <div>
        <v-select
            ref="select"
            :value="value"
            :items="items.items"
            item-text="title"
            return-object
            :placeholder="items.name"
            @change="performAction"
        ></v-select>
    </div>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';

export default {
    props: ['items'],

    data() {
        return {
            remixiconUrl,
            value: null
        };
    },

    methods: {
        performAction(newValue) {
            newValue.action();

            this.$nextTick(() => this.resetValue());
        },
        resetValue() {
            this.value = null;
            this.$refs.select.reset();
        }
    }
};
</script>

<style lang="scss">
.menu-item {
    width: 1.75rem;
    height: 1.75rem;
    color: #0d0d0d;
    border: none;
    background-color: transparent;
    border-radius: 0.4rem;
    padding: 0.25rem;
    margin-right: 0.25rem;

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }

    &.is-active,
    &:hover {
        color: #fff;
        background-color: #0d0d0d;
    }
}
</style>
