<template>
    <div>
        <button
            v-if="icon"
            class="menu-item"
            :class="{ 'is-active': isActive ? isActive() : null }"
            :title="title"
            @click.prevent="action"
        >
            <svg class="remix">
                <use :xlink:href="`${remixiconUrl}#ri-${icon}`" />
            </svg>
        </button>
        <template v-else>
            <button class="menu-item-text" @click.prevent="action">
                {{ title }}
            </button>
        </template>
    </div>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';

export default {
    props: {
        icon: {
            type: String,
            required: false
        },

        title: {
            type: String,
            required: true
        },

        action: {
            type: Function,
            required: true
        },

        isActive: {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            remixiconUrl
        };
    }
};
</script>

<style lang="scss">
.menu-item {
    width: 1.75rem;
    height: 1.75rem;
    color: #0d0d0d;
    border: none;
    background-color: transparent;
    border-radius: 0.4rem;
    padding: 0.25rem;
    margin-right: 0.25rem;

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }

    &.is-active,
    &:hover {
        color: #fff;
        background-color: #0d0d0d;
    }
}
</style>
