import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import EditorMenu from './EditorMenu.vue';

export const editorMixin = {
    components: {
        EditorContent,
        EditorMenu
    },

    props: {
        value: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            editor: null
        };
    },

    computed: {
        modelValue: {
            get() {
                return this.value;
            },
            set() {
                this.$emit('input', this.vlaue);
            }
        }
    },

    watch: {
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value;

            // JSON
            // const isSame = this.editor.getJSON().toString() === value.toString()

            if (isSame) {
                return;
            }

            this.editor.commands.setContent(value, false);
        }
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit.configure({
                    heading: false,
                    blockquote: false,
                    strike: false,
                    code: false,
                    codeBlock: false
                })
            ],
            content: this.modelValue,
            onUpdate: () => {
                if (this.editor.isEmpty) {
                    this.$emit('input', '');
                } else {
                    this.$emit('input', this.editor.getHTML());
                }
            }
        });
    },

    beforeUnmount() {
        this.editor.destroy();
    }
};
